<template>
	<div class="header_nav">
		<div class="header_content">
			<div class="header_content_box">
				<div class="pageChoose">
					<div @click="goPage('0')" class="chooseItem">
						<div :style="state.chooseTag ==  0 ? 'color:#029688' : ''">
							{{ $t("message.indexname.shouye") }}
						</div>
					</div>
					<div @click="goPage('1')" class="chooseItem">
						<div :style="state.chooseTag == 1 ? 'color:#029688' : ''">
							{{ $t("message.indexname.shiyong") }}
						</div>
					</div>
					<div @click="goPage('2')" class="chooseItem">
						<div :style="state.chooseTag == 2 ? 'color:#029688' : ''">
							{{ $t("message.indexname.about") }}
						</div>
					</div>
				</div>
				<div class="language">
					<div style="cursor: pointer;"  @click="targetLang(item, index)" :class="state.current == item.type ? 'activelan' : ''"
						class="lantext" v-for="(item, index) in state.langList" :key="index">
						{{ item.name }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<!-- <div @click="goPage('3')" class="chooseItem">
            <p :style="state.chooseTag == '3' ? 'color:#029688' : ''">个人中心</p>
          </div> -->
<script>
	import {
		reactive,
		onMounted,
		defineProps,
		watch,
		inject
	} from "vue";


	import {
		useRouter
	} from "vue-router";
	import http from "@/api/axios.js";
	export default {
		setup() {
			const reload=inject('reload')
			const props = defineProps({
				chooseTag: {
					type: Number,
					required: 0
				},
			}); // 
			const router = useRouter();
			onMounted(() => {
				state.parameter = router.currentRoute.value.query;
				console.log(router.currentRoute.value.query);
				if (state.parameter.type == 1) {
					//文字搜
					// initList();
				}
				if (state.parameter.type == 2) {
					//图片搜
					// initIconList();
				}
			});
			const state = reactive({
				currentindex: 0,
				current: localStorage.getItem("lang") || "zh-cn", // 注意locale属性！
				langList: [{
						name: "简体中文",
						type: "zh-cn",
					},
					{
						name: "English",
						type: "en-us",
					},
				],
				userInfo: {
					avatar: "",
					name: "",
				},
				chooseTag: "0",
				logo: "",
			});
			watch(
				() => router.currentRoute.value.path,
				(newValue, oldValue) => {
					console.log(newValue, oldValue);
					// 根据路径判断下标显示
					if (newValue == "/homePage") {
						state.chooseTag = 0;
					}
					if (newValue == "/ceshi") {
						state.chooseTag = 1;
					}
					if (newValue == "/aboutwe") {
						state.chooseTag = 2;
					}
					console.log(state.chooseHeader);
				}, {
					immediate: true
				}
			);
			const targetLang = (item, idx) => {
				window.localStorage.setItem("lang", item.type);
				state.current = item.type;
				//reload()
				location.reload();
				// state.parameter = router.currentRoute.value.query;
				// this.$i18n.locale == 'zh' ? this.$i18n.locale = 'en' : this.$i18n.locale = 'zh'   //设置中英文模式
				// if (this.$i18n.locale === 'zh') {
				//   this.language = "en"
				// } else {
				//   this.language = "zh"
				// }
			};

			const logout = () => {
				sessionStorage.clear();
				// router.push(`/userLogin`);
			};
			const pageHome = () => {
				router.push(`/homePage`);
			};
			// const img = () => {
			//   http.post("api/index/websiteLogo").then((res) => {
			//     if (res.data.code == 200) {
			//       state.logo = res.data.data;
			//     }
			//   });
			// };
			const goPage = (index) => {
				if (index == state.chooseTag) {
					return;
				}
				switch (index) {
					case "0":
						state.chooseTag = "0";
						router.push(`/homePage`);
						break;
					case "1":
						state.chooseTag = "1";
						router.push(`/ceshi`);
						break;
					case "2":
						state.chooseTag = "2";
						router.push(`/aboutwe`);
						break;
					case "3":
						state.chooseTag = "3";
						router.push(`/homePage`);
						break;
					default:
						break;
				}

				if (index == 0) {
					state.chooseTag = "0";
					router.push(`/homePage`);
				}
			};
			return {
				state,
				targetLang,
				logout,
				pageHome,
				goPage,
			};
		},

		name: "headerLayout",
	};
</script>

<style scoped>
	.header_nav {
		width: 1140px;
		background: #ffffff;

		position: sticky;
		top: 0;
		z-index: 2;
	}

	.header_nav_p {
		width: 100vw;
		height: 40px;
		background: #f1f3f8;
	}

	.header_nav_box {
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #f3f4f6;
	}

	.header_nav_p_right {
		width: 175px;
		height: 14px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #666666;
		line-height: 19px;
	}

	.header_nav_p_left {
		width: 129px;
		height: 14px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #999999;
		line-height: 19px;
	}

	.header_content {
		width: 1140px;
		padding: 0 30px;
		background: #ffffff;
	}

	.nav_box {
		/* width: 1200px; */
		margin: 0 auto;
		padding-top: 30px;
		height: 56px;
		display: flex;
	}

	.header_content_box {
		margin: 0 auto;
		/* padding-top: 10px; */
		background: #ffffff;

		height: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.layui-input {
		height: 54px;
	}

	.layui-btn_serach {
		height: 54px;
		font-size: 16px;
		width: 106px;
		background: #029688;
	}

	.layui-btn_fabu {
		width: 174px;
		height: 54px;
		border: 2px solid #029688;
	}

	.fabu_box {
		margin-left: 34px;
	}

	.search_box {
		width: 474px;
		height: 54px;
		background: #f1f3f8;
		border: 1px solid #e1e1e1;
		display: flex;
		font-size: 16px;
	}

	.msg_box {
		margin-left: 133px;
		margin-top: 12px;
		position: relative;
	}

	.msg_box img {
		width: 31px;
		height: 31px;
	}

	.msg_box p {
		position: absolute;
		width: 19px;
		height: 19px;
		background-color: red;
		border-radius: 50%;
		text-align: center;
		color: white;
		right: -7px;
		top: -7px;
	}

	.nav_kind {
		width: 238px;
		height: 56px;
		line-height: 56px;
		background: #029688;
		border-radius: 20px 20px 0px 0px;
		text-align: center;
	}

	.kind_icon img {
		width: 20px;
		height: 19px;
		margin-bottom: 5px;
	}

	.kind_text {
		margin-left: 9px;
		width: 73px;
		height: 17px;
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #f1f3f8;
		line-height: 19px;
	}

	.router_box {
		margin-left: 42px;
	}

	.title_position {
		margin-top: 15px;
	}

	.login_box {
		margin-top: 18px;
		margin-left: 145px;
		width: 106px;
		height: 34px;
		line-height: 34px;
		background: #029688;
		border-radius: 17px;
		color: white;
		text-align: center;
	}

	.vip_box {
		margin-top: 18px;
		margin-left: 9px;
		width: 81px;
		height: 34px;
		background: linear-gradient(0deg, #ff7a04, #feb31f);
		border-radius: 17px;
		text-align: center;
		line-height: 34px;
	}

	.vip_box_icon {
		width: 20px;
		height: 18px;
	}

	.vip_box_text {
		width: 27px;
		height: 12px;
		font-size: 16px;
		margin-left: 7px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #ffffff;
	}

	.language {
		display: flex;
		height: 24px;
		align-items: center;
		border-radius: 8px;
		/* border: 1px solid #777777; */
		font-size: 11px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.language .lantext {
		padding: 2px;
		border-radius: 0;
		border: 1px solid #777777;
		color: #777777;
		font-size:10px
	}

	.activelan {
		color: #ffffff !important;
		border: 1px solid #91b98b !important;
		background: linear-gradient(135deg, #9cbc97 0%, #7bb572 100%);
		border-radius: 3px 3px 3px 3px;
	}

	.userinfo {
		display: flex;
		height: 70px;
		align-items: center;
	}

	.userinfo img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	.username {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		line-height: 30px;
		width: 180px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.logout {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		line-height: 30px;
		margin-left: 27px;
		cursor: pointer;
	}

	.pageChoose {
		height: 70px;
		display: flex;
		/* margin-left: 20px; */
		/* background-color:green */
	}

	.chooseItem {
		margin-left: 5px;
		cursor: pointer;
		text-align: center;
		width: 200px;
		line-height: 70px;
	}

	.chooseItem div {
		font-size: 10px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #000000;
	}

	.chooseColor {
		color: #029688 !important;
	}

	@media screen and (max-width: 768px) {
		.header_nav {
			height: 40px;
		}

		.header_content_box {
			padding-top: 10px;
		}

		.chooseItem div {
			font-size: 15px;
		}
		
		.chooseItem {
			width: 85px;
		}
		
		.language {
			height: 26px;
		}
		
		.language .lantext {
			padding: 2px;
			border-radius: 0;
			border: 1px solid #777777;
			color: #777777;
		}

	}
</style>
