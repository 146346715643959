
 

import { createI18n } from 'vue-i18n'

import en from './en'
import zhCN from './zh-CN'

const i18n = createI18n({
  legacy: false,
  globalInjection:true,  
  // locale: 'zh-cn', // 默认显示语言
  locale: localStorage.getItem('lang') || "zh-cn", // 注意locale属性！
  messages: {
    'zh-cn': zhCN,
    'en-us': en
  }
})

export default i18n
