<template>
  <div class="footer">
    <div class="footer_box">
      <div class="footer_top">
        <div>
          <div style="text-align: center;">
            <img class="clsicon" src="../assets/icon/cls.png" alt="" />
            <p class="shouhou">{{ $t("message.indexname.qingsong") }}</p>
          </div>
          <div style="cursor: pointer;" class="row">
            <p
              @click="toNav(index)"
              v-for="(item, index) in state.navlist"
              :key="index"
              class="zixun"
            >
              <!-- {{ item.name }} -->
              {{ $t(`message.footer[${index}].name`) }}
            </p>
          </div>
        </div>
        <div class="qq_box column-between">
          <img src="../assets/icon/erwei.jpg" alt="" />
          <p>{{ $t("message.indexname.fangwen") }}</p>
        </div>
      </div>
    </div>
    <!-- <hr style="margin-top: 73px" /> -->
  </div>
</template>
<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import http from "@/api/axios.js";
import { defineEmits } from "vue"
// const emits = defineEmits(['toidx'])
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      current: 0,
      chooseTag: 0,
      langList: [
        {
          name: "简体中文",
        },
        {
          name: "English",
        },
      ],
      navlist: [
        {
          name: "首页",
          url: "/homePage",
        },
        {
          name: "如何使用",
          url: "/homePage",
        },
        {
          name: "关于我们",
          url: "/homePage",
        },
        {
          name: "用户留言",
          url: "/homePage",
        },
      ],
    });

    const toNav = (index) => {
      // emits("toidx", index)
      console.log(index)
      if (index == state.chooseTag) {
        return;
      }
      state.chooseTag = index

      if (index == 0) {
        router.push(`/homePage`);
      }
      if (index == 1) {
        router.push(`/ceshi`);
      }
      if (index == 2) {
        router.push(`/aboutwe`);
      }
      if (index == 3) {
        router.push(`/homePage?isMes=1`);
      }
    };
    return {
      state,
      toNav,
    };
  },
};
</script>

<style scoped>
.footer {

  /* width: 1140px; */
  /* height: 260px; */
  color: white;
  background: #f5f5f5;
  padding: 10px 40px 10px 40px;

}

.footer_box {
  /* margin: 0px auto; */
}

.footer_top {
  margin: 0px 40px 0 0px;
  display: flex;
  justify-content: space-around;
}
.footer_top .clsicon {
  width: 144px;
  height: 53px;
}

.shouhou {
  /* width: 56px; */
  font-size: 9px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
 color: #242424;
  margin-left: 0px;
}

.zixun {
  /* width: 156px; */
  /* width: 56px; */
  height: 14px;
  font-size: 8px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin-right: 20px;
}

.relation {
  margin-top: 29px;
  width: 410px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #cccccc;
}

.lianxi {
  margin-top: 33px;
  width: 60px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}

.gongsi {
  margin-top: 26px;
  width: 248px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #cccccc;
}

.address {
  margin-top: 19px;
  width: 434px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #cccccc;
}

.email {
  margin-top: 21px;
  width: 146px;
  height: 15px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #cccccc;
}

.qq_box {
  padding-left: 60px;
  border-left: 1px solid #e6e6e6;
}
.qq_box img{
  width: 100px;
  height: 100px;
}
.qq_box p {
  text-align: center;
  font-size: 8px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.jiaoliuqun {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #cccccc;
  text-align: center;
}

.jiaoliuqun img {
  width: 90px;
  height: 22px;
}

.lianjie {
  width: 1190px;
  height: 45px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-right: 15px;
}

.company {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.footer_bottom {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
}

@media screen and (max-width: 768px) {
	.footer_top {
	  margin: 0px 0px 0 0px;
	}
	.footer {
	
	  height: 180px;
	  color: white;
	  background: #f5f5f5;
	  padding-top: 100px;
	
	}
	.zixun{
		margin-right: 6px;
		font-size: 8px;
		font-family: PingFang SC-Medium, PingFang SC
	}
	
	.qq_box p {
	  font-size: 8px;
	}
	
	.qq_box{
		padding-left: 6px;
		width: 39%;
	}
	
	.shouhou {
	  font-size: 6px;
	}
}

</style>