import {createRouter, createWebHashHistory} from 'vue-router'
const WebHashHistory = createWebHashHistory()
export default createRouter({
    history: WebHashHistory,
   
        scrollBehavior(to, from, savePosition) {
          // return 期望滚动到哪个的位置
          return {
            y: 0,
          };
        },

   
    routes: [
        {
            path: '/',   // 跳转路径
            name: 'app',    // 名称
            redirect:"/homePage"
        },
        {
            path: '/homePage',   // 跳转路径
            name: 'homePage',    // 名称
            component:()=>import("../pages/homePage/homePage")
        },
        {
            path: '/aboutwe',   // 跳转路径
            name: 'aboutwe',    // 名称
            component:()=>import("../pages/aboutwe/aboutwe")
        },
        {
            path: '/ceshi',   // 跳转路径
            name: 'ceshi',    // 名称
            component:()=>import("../pages/ceshi/index")
        },
        {
            path: '/myCenterArtisan',   // 跳转路径
            name: 'myCenterArtisan',    // 名称
            component:()=>import("../pages/myCenterArtisan/myCenter")
        },
        {
            path: '/discussPage',   // 跳转路径
            name: 'discussPage',    // 名称
            component:()=>import("../pages/discussPage/index")
        },
    ]
    /*
     *   在这里和vue2一样写路由配置
    */
})

