import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js';
import ElementPlus from 'element-plus' //全局引入
import 'element-plus/dist/index.css'

import i18n from './lang'
import './assets/css/index.css'
// createApp(App).mount('#app')
const app = createApp(App)
app.use(router)
app.use(ElementPlus)

app.use(i18n).mount('#app')









 



