<template>
  <router-view v-if="isRouterAlive"></router-view>
  <div id="app">
    <artisanHeader v-if="state.chooseHeader == 'home'" />
    <mycenterHeader
      :chooseTag="state.chooseTag"
      :keyword="state.keyword"
      v-if="state.chooseHeader == 'search'"
    />
    <div>
      <router-view />
    </div>
    <footerLayout
    @toidx="toidx"
      v-if="state.chooseFooter && state.chooseHeader != 'search'"
    ></footerLayout>
  </div>
</template>
<script>
import { nextTick, provide, reactive, watch, ref} from "vue";
import footerLayout from "@/layout/footer";
import mycenterHeader from "@/layout/mycenterHeader";
import artisanHeader from "@/layout/artisanHeader";
import { useRouter } from "vue-router";
import http from "@/api/axios.js";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      chooseTag: 0,
      keyword: "",
      homePage: ["/homePage", "/aboutwe", "/ceshi"],
      // "/myCenterArtisan",
      searchpage: ["/discussPage"],
      //
      loginform: ["/homePage"],
      artisanpage: [],
      chooseHeader: "home",
      chooseFooter: false,
      isSearch: "",
    });
	const isRouterAlive = ref(true)
	const reload = () =>{
		isRouterAlive.value=false
		nextTick(()=>{
			isRouterAlive.value=true
		})
	}
//     const toidx=(index)=>{
// clo
//     },

    watch(
      () => router.currentRoute.value.path,
      (newValue, oldValue) => {
        console.log(newValue, oldValue);
        // 根据路径判断下标显示
        if (newValue == "/homePage") {
          state.chooseTag = 0;
        }
        if (newValue == "/ceshi") {
          state.chooseTag = 1;
        }
        if (newValue == "/aboutwe") {
          state.chooseTag = 2;
        }
        console.log("96", state.chooseTag);

        let chooseHome = state.homePage.indexOf(newValue);
        let isfooter = state.loginform.indexOf(newValue);
        let chooseUser = state.searchpage.indexOf(newValue);
        // let chooseArtisan = state.artisanpage.indexOf(newValue)
        if (newValue == "/myCenterArtisan") {
          state.chooseHeader = "";
        }
        if (isfooter != -1) {
          state.chooseFooter = false;
        } else {
			setTimeout(() =>{
				state.chooseFooter = true
			},200)
        }

        if (isfooter != -1) {
          state.isSearch = false;
        } else {
          state.isSearch = true;
        }
        if (chooseUser != -1) {
          console.log(666);

          state.chooseHeader = "search";
        }
        if (chooseHome != -1) {
          console.log(556);

          state.chooseHeader = "home";
        }

        console.log(state.chooseHeader);
      },
      { immediate: true }
    );

    //参数
    //     watch(() => router.currentRoute.value, (newValue, oldValue) => {
    //       if(newValue.query.keyword){
    //         state.toparameter = newValue.query.keyword
    // }

    //     }, { immediate: true })
	provide('reload',reload)
    return {
      // toidx,
      state,

    };
  },

  name: "App",

  components: {
    // headerLayout,
    artisanHeader,
    footerLayout,
    mycenterHeader,
  },

  // created() {

  //   http
  //     .post("/api/login/lineUser", {
  //       token: sessionStorage.getItem("token"),
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     });
  // },

  mounted() {
    // setInterval(() => {
    //                     http.post('api/online/userTimes', {
    //                    type:sessionStorage.getItem("islone"),
    //                  }).then(res=>{
    //                  })
    //                }, 59000);
    // window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },

  methods: {
    // init(){
    // var timerer = setInterval(() => {
    //      http.post('api/online/userTimes', {
    //     // onLine:0,
    //     token: sessionStorage.getItem('token')
    //   }).then(res=>{
    //   })
    // }, 59000);
    // },
    // async beforeunloadHandler(e) {
    //   await http.post('api/login/signOut',{
    //     token: sessionStorage.getItem('token')
    //   })
    // },
    // upMessage(conversationID){
    //   var that = this
    //   let promise = tim.setMessageRead({conversationID});
    //   promise.then(function(imResponse) {
    //     // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
    //   }).catch(function(imError) {
    //     // 已读上报失败
    //     console.warn('setMessageRead error:', imError);
    //   });
    //   },
  },
};
</script>

<style>
@media screen and (max-width: 1200px) {
  html {
    /* width: 300px; */
  }
  /* #app{
    width: auto;
    transform:scale(0.9)
  } */
}
body {
  padding: 0;
  margin: 0;
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
  background-color: #f1f3f8;
}

* {
  -ms-overflow-style: none;
}

* {
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}
</style>