
const en ={
message:{
indexname:{
shouye:'Home',
huishouye:'Home',
shiyong:'How to use',
about:'About Us',
qingsong:'Understand food ingredients easily',
onlinemsg:'Message',
sumit:'Submit',
usermsg:'User Message',
msgtype:'Message type',
fangwen:'For more functions, please visit our WeChat mini-program.',
paizhao:'Drag and drop images here',
choosewj:'Select File',
// saoma:'扫码查看 吃了啥'
// searchtext:'请输入要搜索的成分',
},
useruse:{
  hexin:'Core Functions',
  videotitle:'Video Demonstration',
  videoinfo:'Welcome to watch the video to learn how to use our services',
},
aboutus:{//关于我们
  abouttext:'About us',
  xinxi:"Our daily food consumption is a vital energy and nutrient source that greatly affects our  	 health and well-being.",
  xinxi2:"To assist you in making informed, healthier food choices, we offer an efficient and convenient search engine for food ingredients through our website and WeChat mini-program. With this tool, you can learn about the more than 2300 common ingredients' origins, health effects, and functions, as well as keeping track of daily intake. We are continuously updating and expanding our database and working on the English version. ",
	  xinxi8:"We believe that knowledge and technology can empower ourselves and enhance our lives. Let's start here."
},
useruselist:[
  {
    name:'Scan & Search',
    infoList:[
      '- Use the camera to take a picture of the food ingredient section or upload a picture',
      ' - Automatic recognition of all captured ingredients'
  ],
},
{
  name:'Type & Search',
  infoList:[
    '- Directly enter the ingredient name in the search box',
],
},
{
name:'Learn the ingredients',
infoList:[
    
  '- Origin',
  ' - Function',
  '- Health effect',
  '- More information'
],
},
{
name:'Keep records',
infoList:[
'- Personal daily records',
'- Statistics by time',
],
}
],

leixing:[
  {
    'name':'Content error',

  },
  {
    'name':'Feedbacks and suggestions',
  },

],
footer:[
  {
    'name':'Home',

  },
  {
    'name':'How to use',
  },
  {
    'name':'About us',
  },
  {
    'name':'Message',
  }
],
   
    }
  }
  export default en
 