<template>
  <div class="header_nav">
    <div class="header_content">
      <div class="header_content_box">
        <div class="logo">
          <img  src="../assets/icon/cls.png" alt="" />
        </div>
        <div class="xinxi">
          <div @click="toSousuo" class="search">
            <input class="ipt" v-model="keyword" type="text" name="" id="" />
            <!-- :placeholder='$t("message.indexname.searchtext")'  -->
            <div style="cursor: pointer;" class="rightsea">
              <img
                @click="state.istupian = true"
                class="crea"
                src="../assets/icon/cream.png"
                alt=""
              />
              <div @click="toSearch" class="seatext">
                <img class="tosea" src="../assets/icon/search.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="showbox row">
          <div class="language">
          <div style="cursor: pointer;" 
            @click="targetLang(item, index)"
            :class="state.current == item.type ? 'activelan' : ''"
            class="lantext"
            v-for="(item, index) in state.langList"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
			<div style="cursor: pointer;">
				<img @click="state.isshowtwo = true" class="seeticon" src="../assets/icon/setting.png" alt="" />
			</div>
          <div v-if="state.isshowtwo" class="tantwo ">
            <div class="twocontent" >
            <div @click="pageHome(0)" class="twoitem row">
              <img src="../assets/icon/home.png" alt="" />
              <p class="we" style="font-family: PingFang SC-Medium,PingFang SC;">回到首页</p>
            </div>
            <div @click="pageHome(1)"  class="twoitem row">
              <img src="../assets/icon/about.png" alt="" />
              <p class="we" style="font-family: PingFang SC-Medium,PingFang SC;">关于我们</p>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive,defineProps } from "vue";


import http from "@/api/axios.js";
import { useRouter } from "vue-router";
export default {
  setup() {
    
    const router = useRouter();
    const state = reactive({
      current: localStorage.getItem("lang") || "zh-cn", // 注意locale属性！
      langList: [
        {
          name: "简体中文",
          type: "zh-cn",
        },
        {
          name: "English",
          type: "en-us",
        },
      ],
      isshowtwo:false,
     
      logo: "",
    });
    const targetLang = (item, idx) => {
      window.localStorage.setItem("lang", item.type);
      state.current = item.type;
      location.reload();
      // this.$i18n.locale == 'zh' ? this.$i18n.locale = 'en' : this.$i18n.locale = 'zh'   //设置中英文模式
      // if (this.$i18n.locale === 'zh') {
      //   this.language = "en"
      // } else {
      //   this.language = "zh"
      // }
    };
    const logout = () => {
      http.post("api/login/signOut", {
        token: sessionStorage.getItem("token"),
      });
      sessionStorage.clear();
      // router.push(`/userLogin`);
    };
    
    const toSousuo = (idx) => {
      router.push(`/myCenterArtisan`);
    }

    const pageHome = (idx) => {
      state.isshowtwo = false
      if(!idx){
        router.push(`/homePage`);
      }else{
        router.push(`/aboutwe`);
      }
    };
    return {
      state,
      pageHome,
      logout,
      targetLang,
      toSousuo
    };
  },

  name: "headerLayout",

 
  methods: {
    // img() {
    //   http.post("api/index/websiteLogo").then((res) => {
    //     if (res.data.code == 200) {
    //       console.log(111, res.data);
    //       this.state.logo = res.data.data;
    //     }
    //   });
    // },
  }
};
</script>

<style scoped>
.header_nav {
  position: sticky;
  top: 0;
  z-index: 2;
}

.header_nav_p {
  height: 40px;
  background: #f1f3f8;
  width: 1140px;
  padding: 0 30px;

}

.header_nav_box {
  width: 1140px;
  height: 44px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_content {
  /* width: 1920px; */
  background: #ffffff;
}

.nav_box {
  width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
  height: 56px;
  display: flex;
}

.header_content_box {
  margin: 0 auto;
  padding: 0 30px;
  height: 40px;
  /* padding-top: 10px; */
  width: 1140px;
  display: flex;
  align-items: center;
}

.header_content_box img {
  width: 90px;
  height: 28px;
  /* margin-top: 10px; */
}
.header_content_box .seeticon {
  margin-left: 70px;
  width: 20px;
  height: 20px;
}
.tantwo {
  position: absolute;
  background-color: #FFFFFF;
  top: 44px;
  right: 0px;

}
.tantwo .twocontent{
  padding: 0 16px;
  background: #FFFFFF;
box-shadow: 0px 0px 14px 0px rgba(143,143,143,0.3);
border-radius: 12px 12px 12px 12px;

}
.twoitem {
  padding: 0 16px;

}
.twoitem img{
  margin-right: 20px;
  width: 15px;
  height: 15px;
}
.showbox{
  position: relative;
}
.language {
  display: flex;
  height: 24px;
  align-items: center;
  border-radius: 3px;
  /* border: 1px solid #777777; */
  font-size: 11px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.language .lantext {
  padding: 2px;
  border-radius: 0;
  border: 1px solid #777777;
  color: #777777;
}
.activelan {
  color: #ffffff !important;
  border: 1px solid #91b98b !important;
  background: linear-gradient(135deg, #9cbc97 0%, #7bb572 100%);
  border-radius: 3px 3px 3px 3px;
}
input {
  border: 0; /*清除自带的2px的边框*/
  padding: 0; /*清除自带的padding间距*/
  outline: none; /*清除input点击之后的黑色边框*/
}
.xinxi {
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 400;
  color: #777777;
}
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 350px;
  height: 30px;
  background: #ffffff;
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  border: 1px solid #9cbc97;
}
.search .ipt {
  padding: 0 30px;
  height: 20px;
  border: none;
  width: 360px;
  font-size:14px;
}
.layui-input {
  height: 53px;
}
.layui-btn_serach {
  height: 53px;
  font-size: 16px;
  width: 106px;
  background: #029688;
}
.rightsea {
  display: flex;
  align-items: center;
}
.seatext {
  display: flex;
  align-items: center;
  justify-content: center;
 width: 30px;
 height: 30px;
  background-color: #80b678;
}
.search .tosea {
  width: 20px;
  height: 20px;
}
.search .crea {
  margin-right: 20px;
  width: 22px;
  height: 22px;
}

@media screen and (max-width: 768px) {
	.we{
		font-size: 10px;
	}
	
	.twocontent{
		width: 98px;
	}
}
</style>