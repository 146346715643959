const zhCN = {
	message: {
		indexname: { //首页
			shouye: '首页',
			huishouye: '回到首页',
			shiyong: '如何使用',
			about: '关于我们',
			qingsong: '轻松了解食品成分',
			fangwen: '更多功能请访问微信小程序',
			searchtext: '请输入要搜索的成分',
			onlinemsg: '在线留言',
			msgtype: '信息类型',
			sumit: '提交留言',
			usermsg: '用户留言',
			paizhao: '点击拍照',
			choosewj: '选择文件',
			saoma: '扫码查看 吃了啥'
		},
		useruse: { //如何使用
			hexin: '核心功能',
			videotitle: '使用视频',
			videoinfo: '欢迎观看视频，了解如何使用我们的服务',
		},
		aboutus: { //关于我们
			abouttext: '关于我们',
			xinxi: '民以食为天，日常食品摄入是我们人类从外部环境中获取能量和营养最主要的方式。我们所吃食品中究竟包含哪些成分，我们实际上“吃了啥”，对我们的身体健康和发展有着至关重要的影响。  ',
			xinxi2: "我们希望通过本网站与微信小程序（功能更多），为您提供一个高效的查询工具，帮助您在选购和摄入食品时能更快捷、全面地了解食品的所有成分——它们来源于何处，是否健康，是否有害，如何进行记录统计。目前我们可提供2300多种食品常见成分的查询，后续将增加更多，并提供英文版服务。",
			xinxi5: "",
			xinxi6: "",
			xinxi7: "",
			xinxi8: "科技和知识让生活更美好，让我们从这里开始。",
		},
		useruselist: [{
				name: '扫描查询',
				infoList: [
					'使用相机拍摄食品成分表，亦可读取相册图片',
					'自动识别，显示所有成分的信息'
				],
			},
			{
				name: '输入查询',
				infoList: [
					'直接输入成分名称查询',
				],
			},
			{
				name: '了解成分',
				infoList: [
					'成分来源',
					'作用',
					'危害',
					'更多介绍'

				],
			},
			{
				name: '日常记录',
				infoList: [
					'记录摄入的成分',
					'选择时间周期，统计摄入次数',
				],
			}
		],


		leixing: [{
				'name': '内容报错',

			},
			{
				'name': '意见与建议',
			},

		],
		footer: [{
				'name': '首页',

			},
			{
				'name': '如何使用',
			},
			{
				'name': '关于我们',
			},
			{
				'name': '用户留言',
			}
		],

	},
	uoloads: [
		'asdasd1111',
		'aasdasd'
	]
}
export default zhCN
